import { ChangeEvent, useEffect, useState } from 'react';

import { has } from 'lodash';

import { setFormError } from 'utils/form';

import { useTranslation } from 'react-i18next';

import { useHistory, useParams } from 'react-router';

import { ErrorOption, SubmitHandler, useForm } from 'react-hook-form';

import { ApolloError, useLazyQuery, useMutation } from '@apollo/client';

import { Button } from 'ui';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { GENERATION_UNIT_LEASE_CYCLE_QUERY } from 'generationUnits/generationUnitLeaseCycles/graphql/generationUnitLeaseCycleQuery';

import {
  GenerationUnitBillDataUpdated,
  GENERATION_UNIT_BILL_DATA_UPDATE_MUTATION,
  GenerationUnitBillDataUpdateMutationVariables,
} from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/graphql/generationUnitUpdateMutation';

import DangerGenerationUnitBillData from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/components/modal/DangerGenerationUnitBillData';
import UploadGenerationUnitBillData from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/components/modal/UploadGenerationUnitBillData';

import GenerationUnitBillDataFormFields, {
  FormFields,
  FORM_FIELDS,
} from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/components/form/generationUnitBillDataFormFields';

const DASHBOARD_ROUTE = '/dashboard/billing-cycle/lease-cycles';
const DASHBOARD_TITLE = 'Editar dados da fatura da Unidade Geradora';

const BREADCRUMB_PAGES: Page[] = [
  {
    current: false,
    name: 'Ciclos de Locação',
    route: DASHBOARD_ROUTE,
  },
  {
    route: null,
    current: true,
    name: DASHBOARD_TITLE,
  },
];

const UPDATE_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Houve um erro ao tentar atualizar os dados da fatura da Unidade Geradora.',
};

const UPDATE_SUCCESS_TOAST: ToastProps = {
  title: 'Sucesso!',
  variant: 'primary',
  text: 'Sucesso ao editar os dados da fatura da Unidade Geradora.',
};

const UPADTE_DANGER_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Inclusão dos dados da fatura',
  text: 'Você tem certeza de que os dados estão corretos? Eles estão diretamente ligados ao faturamento e não poderão mais ser editados!',
};

const FETCH_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar os Ciclos de Locação.',
};

export default function EditGenerationUnitBillDataPage() {
  const { id } = useParams<{ id: string }>();

  const { push } = useHistory();
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { showLoading, closeLoading, LoadingOverlay } = useLoading();

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);

  const [
    showUploadGenerationUnitBillDataModal,
    setShowUploadGenerationUnitBillDataModal,
  ] = useState(false);

  const [
    showDangerGenerationUnitBillDataModal,
    setShowDangerGenerationUnitBillDataModal,
  ] = useState(false);

  type ModalProps = 'upload' | 'danger';

  const [generationUnitBillDataUpdateMutation, { loading: updateLoading }] =
    useMutation<
      GenerationUnitBillDataUpdated,
      GenerationUnitBillDataUpdateMutationVariables
    >(GENERATION_UNIT_BILL_DATA_UPDATE_MUTATION, {
      onError(error: ApolloError) {
        if (has(error.graphQLErrors[0], 'details')) {
          addToast(UPDATE_ERROR_TOAST);
        }

        setFormError(
          error,
          (field: string, error: ErrorOption) => {
            setError(field as keyof FormFields, error);

            setTimeout(() => clearErrors(), 2500);
          },
          t
        );
      },
      onCompleted() {
        addToast(UPDATE_SUCCESS_TOAST);
        push(DASHBOARD_ROUTE);
      },
    });

  const [
    generationUnitBillDataQuery,
    {
      data,
      error: fetchGenerationUnitBillDataError,
      loading: fetchGenerationUnitBillDataLoading,
    },
  ] = useLazyQuery(GENERATION_UNIT_LEASE_CYCLE_QUERY, {
    variables: {
      id: id,
    },
  });

  const {
    control,
    setError,
    register,
    setValue,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFields>();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  useEffect(() => {
    if (fetchGenerationUnitBillDataError) {
      addToast(FETCH_ERROR_TOAST);
    }
  }, [addToast, fetchGenerationUnitBillDataError]);

  useEffect(() => {
    if (data?.generationUnitBillData) {
      FORM_FIELDS.forEach((field) =>
        setValue(field, data.generationUnitBillData[field])
      );
    }
  }, [data, setValue]);

  useEffect(() => {
    generationUnitBillDataQuery();
  }, [generationUnitBillDataQuery, id]);

  const isLoading = !!(updateLoading || fetchGenerationUnitBillDataLoading);

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }

    closeLoading();
  }, [isLoading, showLoading, closeLoading]);

  const onSubmit: SubmitHandler<FormFields> = (
    GenerationUnitBillDataUpdateInput
  ) => {
    generationUnitBillDataUpdateMutation({
      variables: {
        GenerationUnitBillDataUpdateInput,
      },
    });
  };

  const onClickOpenModal = (modal: ModalProps) => {
    modalShowConfirm();
    (modal === 'upload' && setShowUploadGenerationUnitBillDataModal(true)) ||
      setShowDangerGenerationUnitBillDataModal(true);
  };

  const onClickCloseModal = (modal: ModalProps) => {
    modalCloseConfirm();
    (modal === 'upload' && setShowUploadGenerationUnitBillDataModal(false)) ||
      setShowDangerGenerationUnitBillDataModal(false);
  };

  const onClickCancelModal = () => {
    modalCloseConfirm();
    setUploadedFile(null);
    setShowUploadGenerationUnitBillDataModal(false);
  };

  const handleUploadFile = (file: ChangeEvent<HTMLInputElement>) => {
    if (file.target.files) {
      setUploadedFile(file.target.files[0]);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay />
      <Dashboard
        dashboardHeader={<div className="h-10" />}
        dashboardMainHeaderTitle={
          <DashboardMainHeaderForm
            title={DASHBOARD_TITLE}
            breadcrumbPages={BREADCRUMB_PAGES}
          >
            <Button
              size="sm"
              type="button"
              disabled={isLoading}
              onClick={() => onClickOpenModal('danger')}
            >
              Salvar
            </Button>
          </DashboardMainHeaderForm>
        }
      >
        <GenerationUnitBillDataFormFields
          errors={errors}
          control={control}
          register={register}
          uploadedFile={uploadedFile}
          onClickCancelModal={onClickCancelModal}
          onClickOpenModal={() => onClickOpenModal('upload')}
        />

        {showUploadGenerationUnitBillDataModal && (
          <UploadGenerationUnitBillData
            ModalOverlay={ModalOverlay}
            handleUploadFile={handleUploadFile}
            onClickCancelModal={onClickCancelModal}
            onClickCloseModal={() => onClickCloseModal('upload')}
          />
        )}

        {showDangerGenerationUnitBillDataModal && (
          <DangerGenerationUnitBillData
            danger={UPADTE_DANGER_TOAST}
            ModalOverlay={ModalOverlay}
            onClickContinue={handleSubmit(onSubmit)}
            onClickCloseModal={() => onClickCloseModal('danger')}
          />
        )}
      </Dashboard>
    </form>
  );
}
