export default function UnavailableIcon({ OnClick }: { OnClick?: () => void }) {
  return (
    <div className="flex items-center justify-center pr-1" onClick={OnClick}>
      <svg
        width="27"
        height="27"
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.75 2.25C5.51896 2.25 4.5 3.26896 4.5 4.5V22.5C4.5 23.731 5.51896 24.75 6.75 24.75H20.25C21.481 24.75 22.5 23.731 22.5 22.5V9L15.75 2.25H6.75ZM6.75 4.5H14.625V10.125H20.25V22.5H6.75V4.5ZM9 13.5V15.75H18V13.5H9ZM9 18V20.25H18V18H9Z"
          fill="#717171"
        />
      </svg>
    </div>
  );
}
