import { gql } from '@apollo/client';

export interface GenerationUnitLeaseCycleQueryVariables {
  id: string;
}

export const generationUnitLeaseCycleTypename = 'GenerationUnitLeaseCycle';

export const GENERATION_UNIT_LEASE_CYCLE_QUERY = gql`
  query generationUnitLeaseCycle($id: ID!) {
    generationUnitLeaseCycle(id: $id) {
      id
      generationUnitLeaseCycleDate
      generationUnitLeaseCycleStatus
      generationUnit {
        id
        generationUnitLegalName
      }
    }
  }
`;
