import { gql } from '@apollo/client';

import { ConsumerUnitBillingCaptureStatusCounter } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

interface ConsumerUnitBillingCaptureStatusCounterConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: ConsumerUnitBillingCaptureStatusCounter;
}

export interface ConsumerUnitBillingCaptureStatusCounterList {
  consumerUnitBillingCaptureStatusCounter: ConsumerUnitBillingCaptureStatusCounterConnection;
}

export const consumerUnitBillingCaptureStatusCounterTypename =
  'ConsumerUnitBillingCaptureStatusCounterConnection';

const MAX_PAGE_SIZE = 100;

export const CONSUMER_UNIT_BILLING_CAPTURE_STATUS_COUNTER_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const CONSUMER_UNIT_BILLING_CAPTURE_STATUS_COUNTER_QUERY = gql`
  query consumerUnitBillingCaptureStatusCounter(
    $after: String
    $before: String
    $pageSize: Int
  ) {
    consumerUnitBillingCaptureStatusCounter(
      after: $after
      before: $before
      pageSize: $pageSize
    ) {
      afterCursor
      beforeCursor
      entries {
        consumerUnitBillingCaptureErrorStatusCounter
        consumerUnitBillingCaptureSucessStatusCounter
        consumerUnitBillingCaptureManualStatusCounter
        consumerUnitBillingCaptureWaitingStatusCounter
        consumerUnitBillingCaptureUnavailableStatusCounter
      }
    }
  }
`;
