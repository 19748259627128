import { GenerationUnit } from 'generationUnits/models/generationUnit';

import { ConsumerUnitBillingCapture } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

import { GenerationUnitBillData } from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';

export type GenerationUnitLeaseCycleId = number;

export enum GenerationUnitLeaseCycleStatus {
  OPENED = 'OPENED',
  BILLING = 'BILLING',
  PENDING = 'PENDING',
}

export interface GenerationUnitLeaseCycle {
  id: GenerationUnitLeaseCycleId;
  generationUnit: GenerationUnit;
  generationUnitLeaseCycleDate: Date;
  generationUnitBillData?: GenerationUnitBillData;
  generationUnitLeaseCycleAlreadyFinished: boolean;
  consumerUnitBillingCapture: ConsumerUnitBillingCapture;
  generationUnitLeaseCycleStatus: GenerationUnitLeaseCycleStatus;
}

//OPCIONAL SOMENTE PARA TESTES
//TORNAR OBRIGATÓRIO BILL DATA E BILLING CAPTURE
