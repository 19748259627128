import { Transition } from '@headlessui/react';
import { useRef, useState } from 'react';
import { useFloating, offset, shift, arrow } from '@floating-ui/react-dom';
import { Placement } from '@floating-ui/core';
import { Options } from '@floating-ui/core/src/middleware/offset';
import classnames from 'classnames';

type Props = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLButtonElement> & {
    label: string | React.ReactNode;
    placement: Placement;
    offset?: Options;
    opened?: boolean;
    children: React.ReactNode;
  },
  HTMLButtonElement
>;

export default function Tooltip({
  label,
  children,
  placement,
  offset: offsetPosition,
  className,
  opened,
}: Props) {
  const [open, setOpen] = useState(opened);
  const arrowRef = useRef(null);

  const { x, y, reference, floating, strategy, refs } = useFloating({
    placement: placement,
    middleware: [shift(), offset(offsetPosition), arrow({ element: arrowRef })],
  });

  const showTooltip = () => setOpen(true);
  const disableTooltip = () => setOpen(false);

  return (
    <>
      <button
        ref={reference}
        onMouseOver={showTooltip}
        onMouseOut={disableTooltip}
        className="font-bold"
      >
        {label}
      </button>

      {open && (
        <div
          ref={floating}
          className={'z-10'}
          style={{
            position: strategy,
            left: x ?? 0,
            top: y ?? 0,
          }}
        >
          <div className="relative">
            <div
              ref={arrowRef}
              className={classnames(
                'w-2 h-2 bg-gray-dark550 rotate-45',
                y && {
                  'top-4': y < 0,
                  'bottom-4': y > 0,
                }
              )}
              style={{
                position: strategy,
                left: 0,
              }}
            />

            <Transition
              show={open}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
              beforeEnter={() => refs.reference.current}
              afterLeave={() => null}
              className={classnames(
                'bg-gray-dark550 text-gray-dark400 py-3 px-6 rounded-md text-xs',
                className
              )}
            >
              {opened && (
                <span
                  onClick={() => disableTooltip()}
                  className={'flex row justify-end cursor-pointer'}
                >
                  X
                </span>
              )}

              {children}
            </Transition>
          </div>
        </div>
      )}
    </>
  );
}
