import ErrorIcon from 'assets/icons/counter/ErrorIcon';
import PendingIcon from 'assets/icons/counter/PendingIcon';
import UnavailableIcon from 'assets/icons/counter/UnavailableIcon';

import { ConsumerUnitBillingCaptureStatusCounter } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

export default function ConsumerUnitBillingCaptureStatusCounterItem({
  consumerUnitBillingCaptureStatusCounter,
}: {
  consumerUnitBillingCaptureStatusCounter?: ConsumerUnitBillingCaptureStatusCounter;
}) {
  return (
    <div className="w-full items-center justify-start flex gap-8 pb-10">
      <div className="flex items-center justify-start gap-4">
        <PendingIcon />
        <p className="flex items-center justify-start font-light text-gray-dark400">
          {consumerUnitBillingCaptureStatusCounter?.consumerUnitBillingCaptureWaitingStatusCounter ||
            0}{' '}
          Pendentes de Informação
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <UnavailableIcon />
        <p className="flex items-center justify-start font-light text-gray-dark400">
          {consumerUnitBillingCaptureStatusCounter?.consumerUnitBillingCaptureUnavailableCounter ||
            0}{' '}
          Indisponível
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <ErrorIcon />
        <p className="flex items-center justify-start font-light text-gray-dark400">
          {consumerUnitBillingCaptureStatusCounter?.consumerUnitBillingCaptureErrorStatusCounter ||
            0}{' '}
          Erros de captura
        </p>
      </div>
    </div>
  );
}
