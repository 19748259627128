import { useLocation } from 'react-router-dom';

import { Control, FieldError, UseFormRegister } from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';

import { MdAttachFile, MdClose, MdCloudUpload } from 'react-icons/md';

import {
  BillConsumerUnitId,
  IcmsConsumerUnitType,
  BillConsumedEnergyKwh,
  BillExcessEnergyCredits,
  GenerationUnitBillDataId,
  BillConsumedEnergyCredits,
  BillGeneratedEnergyCredits,
  BillConsumedEnergyCreditsTariffs,
  IcmsConsumerUnitConsumptionGroupType,
} from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';

import { PowerDistributionUnitId } from 'powerDistributionUnits/models/powerDistributionUnit';

import { GenerationUnitConsumptionGroupTypes } from 'generationUnits/models/generationUnit';
import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

export type FormFields = {
  billFileMetadata?: [File];

  id: GenerationUnitBillDataId;
  billConsumerUnitId: BillConsumerUnitId;
  powerDistributionUnitId: PowerDistributionUnitId;

  billDueDate: Date;
  billIssueDate: Date;
  billReferenceDate: Date;
  billNextReadingDate: Date;
  billReadingStartedAt: Date;
  billReadingFinishedAt: Date;

  billTotalValue: string;
  billTotalReadingDays: string;

  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;

  billConsumerUnitType: IcmsConsumerUnitType;
  billConsumerUnitConsumptionGroupType: IcmsConsumerUnitConsumptionGroupType;
};

type Field =
  | 'billDueDate'
  | 'billIssueDate'
  | 'billTotalValue'
  | 'billFileMetadata'
  | 'billReferenceDate'
  | 'billConsumerUnitId'
  | 'billNextReadingDate'
  | 'billReadingStartedAt'
  | 'billConsumerUnitType'
  | 'billTotalReadingDays'
  | 'billReadingFinishedAt'
  | 'billConsumedEnergyKwh'
  | 'billExcessEnergyCredits'
  | 'powerDistributionUnitId'
  | 'billConsumedEnergyCredits'
  | 'billGeneratedEnergyCredits'
  | 'billConsumedEnergyCreditsTariffs'
  | 'billConsumerUnitConsumptionGroupType';

export const FORM_FIELDS: Field[] = [
  'billFileMetadata',

  'billConsumerUnitId',
  'powerDistributionUnitId',

  'billDueDate',
  'billIssueDate',
  'billReferenceDate',
  'billNextReadingDate',
  'billReadingStartedAt',
  'billReadingFinishedAt',

  'billTotalValue',
  'billTotalReadingDays',
  'billConsumedEnergyKwh',

  'billExcessEnergyCredits',
  'billConsumedEnergyCredits',
  'billGeneratedEnergyCredits',
  'billConsumedEnergyCreditsTariffs',

  'billConsumerUnitType',
  'billConsumerUnitConsumptionGroupType',
];

export type FormErrors = {
  billFileMetadata?: [FieldError?];

  id?: FieldError;
  billConsumerUnitId?: FieldError;
  powerDistributionUnitId?: FieldError;

  billDueDate?: FieldError;
  billIssueDate?: FieldError;
  billReferenceDate?: FieldError;
  billNextReadingDate?: FieldError;
  billReadingStartedAt?: FieldError;
  billReadingFinishedAt?: FieldError;

  billTotalValue?: FieldError;
  billTotalReadingDays?: FieldError;

  billConsumedEnergyKwh?: {
    consumedEnergyKwhTotal?: FieldError;
    consumedEnergyKwhOnPeak?: FieldError;
    consumedEnergyKwhOffPeak?: FieldError;
  };

  billExcessEnergyCredits?: {
    excessEnergyCreditsTotal?: FieldError;
    excessEnergyCreditsOnPeak?: FieldError;
    excessEnergyCreditsOffPeak?: FieldError;
  };

  billConsumedEnergyCredits?: {
    consumedEnergyCreditsTotal?: FieldError;
    consumedEnergyCreditsOnPeak?: FieldError;
    consumedEnergyCreditsOffPeak?: FieldError;
  };

  billGeneratedEnergyCredits?: {
    generatedEnergyCreditsTotal?: FieldError;
    generatedEnergyCreditsOnPeak?: FieldError;
    generatedEnergyCreditsOffPeak?: FieldError;
  };

  billConsumedEnergyCreditsTariffs?: {
    consumedEnergyCreditsTariffsTotal?: FieldError;
    consumedEnergyCreditsTariffsOnPeak?: FieldError;
    consumedEnergyCreditsTariffsOffPeak?: FieldError;
  };

  billConsumerUnitType?: FieldError;
  billConsumerUnitConsumptionGroupType?: FieldError;
};

export default function GenerationUnitBillDataFormFields({
  errors,
  control,
  register,
  uploadedFile,
  onClickOpenModal,
  onClickCancelModal,
}: {
  errors: FormErrors;
  uploadedFile?: File | null;
  control: Control<FormFields>;
  onClickOpenModal?: () => void;
  onClickCancelModal?: () => void;
  register: UseFormRegister<FormFields>;
}) {
  const location = useLocation();

  const generationUnitLeaseCycles = location.state as GenerationUnitLeaseCycle;

  const HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B =
    generationUnitLeaseCycles?.generationUnit?.generationUnitConsumptionGroupType ===
    GenerationUnitConsumptionGroupTypes.B;

  return (
    <div className="w-full flex flex-col gap-8">
      <div className="w-auto">
        {uploadedFile ? (
          <Button
            size="none"
            className="flex justify-start items-center p-2"
            style={{
              backgroundColor: 'transparent',
              border: '2px solid #cccccc5d',
            }}
          >
            <MdAttachFile
              size={25}
              color="#cccccc5d"
              style={{
                marginRight: '10px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full">
              <p className="font-normal text-sm text-gray-dark400">
                {uploadedFile.name}
              </p>
            </div>
            <MdClose size={30} color="#cccccc5d" onClick={onClickCancelModal} />
          </Button>
        ) : (
          <Button
            size="none"
            type="button"
            variant="tertiaryGray"
            onClick={onClickOpenModal}
            className="flex justify-start items-center p-2"
          >
            <MdCloudUpload
              size={30}
              color="#B6B6B6"
              style={{
                marginLeft: '5px',
                marginRight: '5px',
              }}
            />
            <div className="flex flex-col justify-center items-start w-full cursor-pointer">
              <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
                Importar fatura
              </p>
            </div>
          </Button>
        )}
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="date"
          id="billDueDate"
          placeholder="DD/MM/AAAA"
          label="Data de vencimento"
          error={errors.billDueDate?.message}
          {...register('billDueDate', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          id="billIssueDate"
          placeholder="DD/MM/AAAA"
          label="Data de disponibilidade"
          error={errors.billIssueDate?.message}
          {...register('billIssueDate', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          id="billReferenceDate"
          label="Mês de referência"
          placeholder="DD/MM/AAAA"
          error={errors.billReferenceDate?.message}
          {...register('billReferenceDate', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="date"
          id="billDueDate"
          placeholder="DD/MM/AAAA"
          label="Data leitura anterior"
          error={errors.billReadingStartedAt?.message}
          {...register('billReadingStartedAt', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          placeholder="DD/MM/AAAA"
          id="billReadingFinishedAt"
          label="Data leitura atual"
          error={errors.billReadingFinishedAt?.message}
          {...register('billReadingFinishedAt', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="date"
          id="billTotalValue"
          placeholder="DD/MM/AAAA"
          label="Data leitura futura"
          error={errors.billTotalValue?.message}
          {...register('billTotalValue', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="currency"
          id="billDueDate"
          placeholder="R$ 0,00"
          label="Valor da fatura da Unidade Geradora"
          error={errors.billReadingStartedAt?.message}
          {...register('billReadingStartedAt', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          label="Créditos gerados ponta"
          id="generatedEnergyCreditsOnPeak"
          disabled={HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B}
          error={
            errors.billGeneratedEnergyCredits?.generatedEnergyCreditsOnPeak?.message
          }
          {...register('billGeneratedEnergyCredits.generatedEnergyCreditsOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="generatedEnergyCreditsOffPeak"
          label="Créditos gerados fora ponta"
          error={
            errors.billGeneratedEnergyCredits?.generatedEnergyCreditsOffPeak?.message
          }
          {...register('billGeneratedEnergyCredits.generatedEnergyCreditsOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="consumedEnergyCreditsOnPeak"
          label="Créditos utilizados ponta"
          disabled={HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B}
          error={
            errors.billConsumedEnergyCredits?.consumedEnergyCreditsOnPeak?.message
          }
          {...register('billConsumedEnergyCredits.consumedEnergyCreditsOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="consumedEnergyCreditsOffPeak"
          label="Créditos utilizados fora ponta"
          error={
            errors.billConsumedEnergyCredits?.consumedEnergyCreditsOffPeak?.message
          }
          {...register('billConsumedEnergyCredits.consumedEnergyCreditsOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
      <div className="grid grid-cols-4 gap-8">
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="excessEnergyCreditsOnPeak"
          label="Créditos excedentes ponta"
          disabled={HAS_GENERATION_UNIT_CONSUMPTION_GROUP_TYPE_B}
          error={errors.billExcessEnergyCredits?.excessEnergyCreditsOnPeak?.message}
          {...register('billExcessEnergyCredits.excessEnergyCreditsOnPeak', {
            required: 'Campo obrigatório',
          })}
        />
        <TextField
          required
          mask="decimal"
          placeholder="0,00"
          id="excessEnergyCreditsOffPeak"
          label="Créditos excedentes fora ponta"
          error={errors.billExcessEnergyCredits?.excessEnergyCreditsOffPeak?.message}
          {...register('billExcessEnergyCredits.excessEnergyCreditsOffPeak', {
            required: 'Campo obrigatório',
          })}
        />
      </div>
    </div>
  );
}
