import { gql } from '@apollo/client';

import { PowerDistributionUnitId } from 'powerDistributionUnits/models/powerDistributionUnit';

import {
  BillConsumerUnitId,
  IcmsConsumerUnitType,
  BillConsumedEnergyKwh,
  GenerationUnitBillData,
  BillExcessEnergyCredits,
  GenerationUnitBillDataId,
  BillConsumedEnergyCredits,
  BillGeneratedEnergyCredits,
  BillConsumedEnergyCreditsTariffs,
  IcmsConsumerUnitConsumptionGroupType,
} from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';

export interface GenerationUnitBillDataCreated {
  generationUnitBillDataCreate: GenerationUnitBillData;
}

export interface GenerationUnitBillDataCreateMutationInput {
  id: GenerationUnitBillDataId;
  billConsumerUnitId: BillConsumerUnitId;
  powerDistributionUnitId: PowerDistributionUnitId;

  billDueDate: Date;
  billIssueDate: Date;
  billReferenceDate: Date;
  billNextReadingDate: Date;
  billReadingStartedAt: Date;
  billReadingFinishedAt: Date;

  billTotalValue: string;
  billTotalReadingDays: string;

  billConsumedEnergyKwh: BillConsumedEnergyKwh;
  billExcessEnergyCredits: BillExcessEnergyCredits;
  billConsumedEnergyCredits: BillConsumedEnergyCredits;
  billGeneratedEnergyCredits: BillGeneratedEnergyCredits;
  billConsumedEnergyCreditsTariffs: BillConsumedEnergyCreditsTariffs;

  billConsumerUnitType: IcmsConsumerUnitType;
  billConsumerUnitConsumptionGroupType: IcmsConsumerUnitConsumptionGroupType;
}

export interface GenerationUnitBillDataMutationVariables {
  generationUnitBillDataCreateInput: GenerationUnitBillDataCreateMutationInput;
}

export const GENERATION_UNIT_BILL_DATA_CREATE_MUTATION = gql`
  mutation generationUnitBillDataCreate(
    $generationUnitBillDataCreateInput: GenerationUnitBillDataCreateInput!
  ) {
    generationUnitBillDataCreate(input: $GenerationUnitBillDataCreateInput) {
      id
      billConsumerUnitId
      powerDistributionUnitId

      billDueDate
      billIssueDate
      billReferenceDate
      billNextReadingDate
      billReadingStartedAt
      billReadingFinishedAt

      billTotalValue
      billTotalReadingDays

      billConsumerUnitType
      billConsumerUnitConsumptionGroupType

      billConsumedEnergyKwh {
        consumedEnergyKwhTotal
        consumedEnergyKwhOnPeak
        consumedEnergyKwhOffPeak
      }

      billExcessEnergyCredits {
        excessEnergyCreditsTotal
        excessEnergyCreditsOnPeak
        excessEnergyCreditsOffPeak
      }

      billConsumedEnergyCredits {
        consumedEnergyCreditsTotal
        consumedEnergyCreditsOnPeak
        consumedEnergyCreditsOffPeak
      }

      billGeneratedEnergyCredits {
        generatedEnergyCreditsTotal
        generatedEnergyCreditsOnPeak
        generatedEnergyCreditsOffPeak
      }

      billConsumedEnergyCreditsTariffs {
        consumedEnergyCreditsTariffsTotal
        consumedEnergyCreditsTariffsOnPeak
        consumedEnergyCreditsTariffsOffPeak
      }
    }
  }
`;
