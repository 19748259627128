import { gql } from '@apollo/client';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

interface GenerationUnitLeaseCycleConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: GenerationUnitLeaseCycle[];
}

export interface GenerationUnitLeaseCycleList {
  generationUnitLeaseCycles: GenerationUnitLeaseCycleConnection;
}

export const generationUnitLeaseCyclesTypename =
  'GenerationUnitLeaseCycleConnection';

const MAX_PAGE_SIZE = 100;

export const GENERATION_UNIT_LEASE_CYCLES_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const GENERATION_UNIT_LEASE_CYCLES_QUERY = gql`
  query generationUnitLeaseCycles(
    $after: String
    $before: String
    $pageSize: Int
    $filter: GenerationUnitLeaseCyclesFilterOptions
  ) {
    generationUnitLeaseCycles(
      after: $after
      before: $before
      pageSize: $pageSize
      filter: $filter
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        generationUnitLeaseCycleDate
        generationUnitLeaseCycleStatus
        generationUnit {
          id
          generationUnitLegalName
        }
      }
    }
  }
`;
