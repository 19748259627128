import { gql } from '@apollo/client';

import { GenerationUnitBillData } from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/models/generationUnitBillData';

export interface GenerationUnitBillDataUpdateMutationInput {
  id: number;
}

export interface GenerationUnitBillDataUpdated {
  GenerationUnitBillData: GenerationUnitBillData;
}

export interface GenerationUnitBillDataUpdateMutationVariables {
  GenerationUnitBillDataUpdateInput: GenerationUnitBillDataUpdateMutationInput;
}

export const GENERATION_UNIT_BILL_DATA_UPDATE_MUTATION = gql`
  mutation generationUnitBillDataUpdate(
    $generationUnitBillDataUpdateInput: GenerationUnitBillDataUpdateInput!
  ) {
    generationUnitBillDataUpdate(input: $GenerationUnitBillDataUpdateInput) {
      id
      billConsumerUnitId
      powerDistributionUnitId

      billDueDate
      billIssueDate
      billReferenceDate
      billNextReadingDate
      billReadingStartedAt
      billReadingFinishedAt

      billTotalValue
      billTotalReadingDays

      billConsumerUnitType
      billConsumerUnitConsumptionGroupType

      billConsumedEnergyKwh {
        consumedEnergyKwhTotal
        consumedEnergyKwhOnPeak
        consumedEnergyKwhOffPeak
      }

      billExcessEnergyCredits {
        excessEnergyCreditsTotal
        excessEnergyCreditsOnPeak
        excessEnergyCreditsOffPeak
      }

      billConsumedEnergyCredits {
        consumedEnergyCreditsTotal
        consumedEnergyCreditsOnPeak
        consumedEnergyCreditsOffPeak
      }

      billGeneratedEnergyCredits {
        generatedEnergyCreditsTotal
        generatedEnergyCreditsOnPeak
        generatedEnergyCreditsOffPeak
      }

      billConsumedEnergyCreditsTariffs {
        consumedEnergyCreditsTariffsTotal
        consumedEnergyCreditsTariffsOnPeak
        consumedEnergyCreditsTariffsOffPeak
      }
    }
  }
`;
