import { useHistory } from 'react-router-dom';

import { Button } from 'ui';
import { ModalFormProps } from 'ui/models/overlay';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ToastProps } from 'ui/contexts/overlay/Toast';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

export type GenerationUnitBillDataModal = {
  show: boolean;
  pathname: string;
  generationUnitLeaseCycle: GenerationUnitLeaseCycle | null;
};

type Props = {
  danger: ToastProps;
  onClickContinue?: () => void;
  onClickCloseModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
  generationUnitBillData?: GenerationUnitBillDataModal;
};

export default function DangerGenerationUnitBillData({
  danger,
  ModalOverlay,
  onClickContinue,
  onClickCloseModal,
  generationUnitBillData,
}: Props) {
  const { push } = useHistory();
  const { LoadingOverlay } = useLoading();

  const onClickCreate = () =>
    push({
      pathname: generationUnitBillData?.pathname,
      state: generationUnitBillData?.generationUnitLeaseCycle,
    });

  return (
    <ModalOverlay title={danger.title} variant={danger.variant}>
      <div className="grid grid-cols-12">
        <div className="col-start-1 col-end-12">
          <p className="text-gray-dark400 font-small text-sm">{danger.text}</p>
        </div>
      </div>
      <div className="flex flex-row-reverse mt-8 gap-4">
        <Button
          size="md"
          variant="primaryRed"
          onClick={(generationUnitBillData && onClickCreate) || onClickContinue}
        >
          <p className="font-medium">Continuar</p>
        </Button>

        <Button variant="primaryGray" size="md" onClick={onClickCloseModal}>
          <p className="font-medium">Cancelar</p>
        </Button>
      </div>
      <LoadingOverlay />
    </ModalOverlay>
  );
}
