import { useEffect, useState } from 'react';

import { Control, Controller, FieldError, UseFormRegister } from 'react-hook-form';

import omit from 'lodash/omit';

import { Button } from 'ui';
import SelectField from 'ui/form/SelectField';
import { Option } from 'ui/components/form/SelectInput';
import DatePickerFieldSingleView from 'ui/form/DatePickerFieldSingleView';

import { GenerationUnitSelectModel } from 'generationUnits/models/generationUnit';
import { GenerationUnitLeaseCycleStatus } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

import { ConsumerUnitBillingCaptureStatus } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

export type FormFilters = {
  generationUnitId: number;
  generationUnitLeaseCycleDate: Date;
  generationUnitLeaseCycleStatus: GenerationUnitLeaseCycleStatus;
  consumerUnitBillingCaptureStatus: ConsumerUnitBillingCaptureStatus;
};

export type FormErrors = {
  generationUnitId?: FieldError;
  generationUnitLeaseCycleDate?: FieldError;
  generationUnitLeaseCycleStatus?: FieldError;
  consumerUnitBillingCaptureStatus?: FieldError;
};

export default function GenerationUnitLeaseCyclesFormFilters({
  errors,
  control,
  isLoading,
  generationUnits,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  register: UseFormRegister<FormFilters>;
  generationUnits?: GenerationUnitSelectModel[];
}) {
  const [generationUnitOptions, setGenerationUnitOptions] = useState<
    Option<number>[]
  >([]);

  const generationUnitLeaseCycleStatus = [
    {
      key: 'OPENED',
      value: 'EM ABERTO',
    },
    {
      key: 'BILLING',
      value: 'FATURANDO',
    },
    {
      key: 'PENDING',
      value: 'PENDENTE',
    },
    {
      key: 'WAITING',
      value: 'AGUARDANDO',
    },
    {
      key: 'FINISHED',
      value: 'FINALIZADO',
    },
  ];

  const consumerUnitBillingCaptureStatus = [
    {
      key: 'ERROR',
      value: 'ERRO',
    },
    {
      key: 'MANUAL',
      value: 'MANUAL',
    },
    {
      key: 'SUCCESS',
      value: 'SUCESSO',
    },
    {
      key: 'WAITING',
      value: 'AGUARDANDO',
    },
  ];

  useEffect(
    () =>
      setGenerationUnitOptions(
        (generationUnits instanceof Array &&
          generationUnits.map((generationUnit) => ({
            key: Number(generationUnit.id),
            value: generationUnit.generationUnitLegalName,
          }))) ||
          []
      ),
    [generationUnits]
  );

  return (
    <div className="grid grid-cols-6 gap-4 pb-6 items-end">
      <Controller
        name="generationUnitId"
        control={control}
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref')}
            id="generationUnitId"
            label="Unidade Geradora"
            emptyOptionLabel="Selecionar"
            options={generationUnitOptions}
            error={errors.generationUnitId?.message}
          />
        )}
      />

      <Controller
        control={control}
        name="generationUnitLeaseCycleDate"
        render={({ field }) => (
          <DatePickerFieldSingleView
            {...omit(field, 'ref')}
            labelDate="Ciclo de Locação"
            placeholder="MM/AA"
            options={{ month: '2-digit', year: '2-digit' }}
            id="generationUnitLeaseCycleDate"
            error={errors.generationUnitLeaseCycleDate?.message}
          />
        )}
      />

      <Controller
        name="generationUnitLeaseCycleStatus"
        control={control}
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref')}
            id="generationUnitLeaseCycleStatus"
            label="Status do Ciclo"
            emptyOptionLabel="Selecionar"
            options={generationUnitLeaseCycleStatus}
            error={errors.generationUnitLeaseCycleStatus?.message}
          />
        )}
      />

      <Controller
        name="consumerUnitBillingCaptureStatus"
        control={control}
        render={({ field }) => (
          <SelectField
            {...omit(field, 'ref')}
            id="consumerUnitBillingCaptureStatus"
            label="Status da Captura"
            emptyOptionLabel="Selecionar"
            options={consumerUnitBillingCaptureStatus}
            error={errors.consumerUnitBillingCaptureStatus?.message}
          />
        )}
      />

      <Button type="submit" size="sm" disabled={isLoading} className="self-end w-22">
        Filtrar
      </Button>
    </div>
  );
}
