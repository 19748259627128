import { Tooltip } from 'ui/components/tooltip';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import ErrorIcon from 'assets/icons/list/ErrorIcon';
import PendingIcon from 'assets/icons/list/PendingIcon';
import SuccessIcon from 'assets/icons/list/SuccessIcon';
import UnavailableIcon from 'assets/icons/list/UnavailableIcon';

import {
  GenerationUnitLeaseCycle,
  GenerationUnitLeaseCycleStatus,
} from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

import { ConsumerUnitBillingCaptureStatus } from 'consumerUnitBillingCapture/model/consumerUnitBillingCaptures';

type Props = {
  onClickCreateButton: (
    pathname: string,
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => void;
  onClickOpenBillDetails: (
    generationUnitLeaseCycle: GenerationUnitLeaseCycle
  ) => void;
  generationUnitLeaseCycles: GenerationUnitLeaseCycle;
};

export default function GenerationUnitLeaseCyclesTableItem({
  onClickCreateButton,
  onClickOpenBillDetails,
  generationUnitLeaseCycles: {
    id,
    generationUnit,
    generationUnitBillData,
    consumerUnitBillingCapture,
    generationUnitLeaseCycleDate,
    generationUnitLeaseCycleStatus,
    generationUnitLeaseCycleAlreadyFinished,
  },
}: Props) {
  const GENERATION_UNIT_LEASE_CYCLE: GenerationUnitLeaseCycle = {
    id,
    generationUnit,
    generationUnitBillData,
    consumerUnitBillingCapture,
    generationUnitLeaseCycleDate,
    generationUnitLeaseCycleStatus,
    generationUnitLeaseCycleAlreadyFinished,
  };

  const LOCALE_DATE_CONFIG = {
    locale: 'pt-BR',
  };

  const CREATE_ROUTE = '/dashboard/billing-cycle/lease-cycles/create';
  const EDIT_ROUTE = `/dashboard/billing-cycle/lease-cycles/${id}/edit`;

  const HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED =
    !!generationUnitBillData &&
    consumerUnitBillingCapture.consumerUnitBillingCaptureStatus ===
      ConsumerUnitBillingCaptureStatus.SUCCESS;

  const TRANSLATE_GENERATION_UNIT_LEASE_CYCLE_STATUS_TO_PORTUGUESE = {
    [GenerationUnitLeaseCycleStatus.OPENED]: 'EM ABERTO',
    [GenerationUnitLeaseCycleStatus.PENDING]: 'PENDENTE',
    [GenerationUnitLeaseCycleStatus.BILLING]:
      (HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED &&
        generationUnitLeaseCycleAlreadyFinished &&
        'FINALIZADO') ||
      'FATURANDO',
  };

  const ICON_STATUS_SUCCESS_BILL_DATA = (HAS_BILL_DATA_AND_BILLING_WAS_CAPTURED && (
    <Tooltip
      label={
        <SuccessIcon
          OnClick={() =>
            onClickOpenBillDetails({
              id,
              generationUnit,
              generationUnitBillData,
              consumerUnitBillingCapture,
              generationUnitLeaseCycleDate,
              generationUnitLeaseCycleStatus,
              generationUnitLeaseCycleAlreadyFinished,
            })
          }
        />
      }
      placement="bottom"
    >
      Detalhes da fatura
    </Tooltip>
  )) || (
    <Tooltip
      label={
        <PendingIcon
          OnClick={() =>
            onClickCreateButton(EDIT_ROUTE, GENERATION_UNIT_LEASE_CYCLE)
          }
        />
      }
      placement="bottom"
    >
      Pendente de informação
    </Tooltip>
  );

  const ICON_STATUS = {
    [ConsumerUnitBillingCaptureStatus.MANUAL]: (
      <PendingIcon
        OnClick={() => onClickCreateButton(EDIT_ROUTE, GENERATION_UNIT_LEASE_CYCLE)}
      />
    ),
    [ConsumerUnitBillingCaptureStatus.ERROR]: (
      <Tooltip
        label={
          <ErrorIcon
            OnClick={() =>
              onClickCreateButton(CREATE_ROUTE, GENERATION_UNIT_LEASE_CYCLE)
            }
          />
        }
        placement="bottom"
      >
        Falha na captura da fatura, reporte ao time de Produto
      </Tooltip>
    ),
    [ConsumerUnitBillingCaptureStatus.SUCCESS]: ICON_STATUS_SUCCESS_BILL_DATA,
    [ConsumerUnitBillingCaptureStatus.WAITING]: (
      <Tooltip
        label={
          <UnavailableIcon
            OnClick={() =>
              onClickCreateButton(CREATE_ROUTE, GENERATION_UNIT_LEASE_CYCLE)
            }
          />
        }
        placement="bottom"
      >
        Fatura indisponível
      </Tooltip>
    ),
  };

  return (
    <TableBodyRow dataTestId={`generation-unit-lease-cycles-row-id-${id}`}>
      <TableBodyColumn>{generationUnit.generationUnitLegalName}</TableBodyColumn>
      <TableBodyColumn>
        {generationUnitLeaseCycleDate.toLocaleDateString(LOCALE_DATE_CONFIG.locale)}
      </TableBodyColumn>
      <TableBodyColumn>
        {
          TRANSLATE_GENERATION_UNIT_LEASE_CYCLE_STATUS_TO_PORTUGUESE[
            generationUnitLeaseCycleStatus
          ]
        }
      </TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          label={<p className="underline">{30 /*CONSUMER UNIT COUNT */}</p>}
          placement="bottom"
        >
          Distribuição de créditos
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn className="flex gap-x-3 items-end justify-end">
        {ICON_STATUS[consumerUnitBillingCapture.consumerUnitBillingCaptureStatus]}
      </TableBodyColumn>
    </TableBodyRow>
  );
}
