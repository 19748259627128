import Breadcrumbs, { Page } from './Breadcrumbs';

type Props = {
  title: string;
  children?: React.ReactNode;
  breadcrumbPages?: Page[];
};

export default function DashboardMainHeaderForm({
  breadcrumbPages,
  title,
  children,
}: Props) {
  return (
    <header className="pt-6">
      <div className="max-w-7xl mx-auto px-4 flex items-center gap-x-3 justify-between">
        <h1 className="text-2xl font-bold text-gray-dark400">{title}</h1>
        <div className="flex gap-x-3">{children}</div>
      </div>
      <div className="max-w-7xl mx-auto px-4 mb-5">
        <Breadcrumbs pages={breadcrumbPages} />
        <p className="leading-6 font-light text-gray-dark500">{''}</p>
      </div>
    </header>
  );
}
