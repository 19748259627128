import { Button } from 'ui';

import { MdClose } from 'react-icons/md';
import { IoMdCloudDownload } from 'react-icons/io';

import { GenerationUnitLeaseCycle } from 'generationUnits/generationUnitLeaseCycles/models/generationUnitLeaseCycles';

export type Props = {
  onClickOpenModal?: () => void;
  onClickCloseModal?: () => void;
  generationUnitLeaseCycle: GenerationUnitLeaseCycle;
};

const LOCALE_DATE_CONFIG = {
  locale: 'pt-BR',
};

const convertToBRL = (value: number) => {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

const convertToDecimal = (value: string) => {
  return Number(value).toFixed(2);
};

export default function GenerationUnitBillDataDetailsItem({
  onClickOpenModal,
  onClickCloseModal,
  generationUnitLeaseCycle,
}: Props) {
  return (
    <div className="flex flex-col items-start justify-start gap-2 h-screen fixed bg-gray-dark700 right-0 top-0 border-l border-gray-dark600">
      <div className="flex px-10 py-8 items-center justify-start gap-12 border-b border-gray-dark600">
        <h1 className="text-white w-full font-normal text-2xl">
          Detalhes da fatura
        </h1>
        <Button
          type="button"
          size="none"
          variant="tertiaryGray"
          style={{
            padding: '.1rem',
          }}
        >
          <MdClose size={20} color="#6B6B6B" onClick={onClickCloseModal} />
        </Button>
      </div>
      <div className="w-full flex flex-col px-10 py-8 gap-4">
        <div>
          <h2 className="text-white leading-4">Mês de referência da fatura:</h2>
          <p className="text-gray-dark400">
            {generationUnitLeaseCycle.generationUnitBillData?.billReferenceDate.toLocaleDateString(
              LOCALE_DATE_CONFIG.locale
            )}
          </p>
        </div>
        <div>
          <h2 className="text-white leading-4">Ciclo de geração:</h2>
          <p className="text-gray-dark400">
            {generationUnitLeaseCycle.generationUnitBillData?.billReadingStartedAt.toLocaleDateString(
              LOCALE_DATE_CONFIG.locale
            )}{' '}
            -{' '}
            {generationUnitLeaseCycle.generationUnitBillData?.billReadingFinishedAt.toLocaleDateString(
              LOCALE_DATE_CONFIG.locale
            )}
          </p>
        </div>
        <div>
          <h2 className="text-white leading-4">Data de leitura futura:</h2>
          <p className="text-gray-dark400">
            {generationUnitLeaseCycle.generationUnitBillData?.billNextReadingDate.toLocaleDateString(
              LOCALE_DATE_CONFIG.locale
            )}
          </p>
        </div>
        <div>
          <h2 className="text-white leading-4">Data de disponibilização:</h2>
          <p className="text-gray-dark400">
            {generationUnitLeaseCycle.generationUnitBillData?.billIssueDate.toLocaleDateString(
              LOCALE_DATE_CONFIG.locale
            )}
          </p>
        </div>
        <div>
          <h2 className="text-white leading-4">Valor da fatura:</h2>
          <p className="text-gray-dark400">
            {convertToBRL(
              Number(generationUnitLeaseCycle.generationUnitBillData?.billTotalValue)
            )}
          </p>
        </div>
      </div>
      <div className="flex w-full flex-col px-10 py-8 border-t border-gray-dark600 gap-4">
        <h2 className="text-white leading-4">
          Fator de capacidade:{' '}
          <span className="text-gray-dark400">
            {convertToDecimal(
              generationUnitLeaseCycle.generationUnit
                ?.generationUnitCapacityFactorAverage
            )}
          </span>
        </h2>
        <div>
          <h2 className="text-white leading-4">Geração:</h2>
          <p className="text-gray-dark400">
            {convertToDecimal(
              generationUnitLeaseCycle.generationUnitBillData
                ?.billGeneratedEnergyCredits?.generatedEnergyCreditsTotal || '0'
            )}
          </p>
        </div>
        <div>
          <h2 className="text-white leading-4">Utilizados:</h2>
          <p className="text-gray-dark400">
            {convertToDecimal(
              generationUnitLeaseCycle.generationUnitBillData
                ?.billConsumedEnergyCredits?.consumedEnergyCreditsTotal || '0'
            )}
          </p>
        </div>
        <div>
          <h2 className="text-white">Excedentes:</h2>
          <h1 className="text-gray-dark400 text-2xl font-bold">
            {convertToBRL(
              Number(
                generationUnitLeaseCycle.generationUnitBillData
                  ?.billExcessEnergyCredits?.excessEnergyCreditsTotal
              )
            )}
          </h1>
        </div>
      </div>
      <a
        href={
          generationUnitLeaseCycle.consumerUnitBillingCapture
            .consumerUnitBillingCaptureUrl
        }
        target="_blank"
        rel="noreferrer"
      >
        <Button
          size="none"
          type="button"
          variant="tertiaryGray"
          onClick={onClickOpenModal}
          className="flex justify-start items-center p-2 ml-10"
        >
          <IoMdCloudDownload
            size={30}
            color="#B6B6B6"
            style={{
              marginLeft: '5px',
              marginRight: '5px',
            }}
          />
          <div className="flex flex-col justify-center items-start cursor-pointer">
            <p className="font-normal text-sm text-gray-dark300 cursor-pointer">
              Baixar fatura
            </p>
          </div>
        </Button>
      </a>
    </div>
  );
}
