import { ConsumerUnit } from 'consumerUnits/model/consumerUnit';

export type ConsumerUnitBillingCaptureId = number;

export enum ConsumerUnitBillingCaptureStatus {
  ERROR = 'ERROR',
  MANUAL = 'MANUAL',
  SUCCESS = 'SUCCESS',
  WAITING = 'WAITING',
}

export type ConsumerUnitBillingCaptureStatusCounter = {
  consumerUnitBillingCaptureErrorStatusCounter: number;
  consumerUnitBillingCaptureUnavailableCounter: number;
  consumerUnitBillingCaptureSucessStatusCounter: number;
  consumerUnitBillingCaptureManualStatusCounter: number;
  consumerUnitBillingCaptureWaitingStatusCounter: number;
};

export type ConsumerUnitBillingCapture = {
  id: ConsumerUnitBillingCaptureId;

  consumerUnitId: number;
  consumerUnit?: ConsumerUnit;

  powerDistributionUnitId: number;

  consumerUnitBillingCaptureUrl: string;
  consumerUnitBillingCaptureError: string | null;
  consumerUnitBillingCaptureReferenceMonth: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
  consumerUnitBillingCaptureStatus: ConsumerUnitBillingCaptureStatus;
};
