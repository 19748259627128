import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';
import { Pagination } from 'dashboard/components/table';
import { useLoading } from 'ui/contexts/overlay/Loading';
import useToastContext from 'ui/hooks/useToast';
import {
  APPORTIONMENT_CYCLE_CONSUMER_UNITS_QUERY,
  ApportionmentCycleConsumerUnitsList,
} from 'apportionmentCycles/graphql/apportionmentCycleConsumerUnitsQuery';
import { ApportionmentCycleConsumerUnit } from 'apportionmentCycles/models/apportionmentCycleConsumerUnit';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import CsvButton from 'apportionmentCycles/components/apportionmentCycleConsumerUnits/CsvButton';
import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';
import ApportionmentCycleConsumerUnitsTable from 'apportionmentCycles/components/table/ApportionmentCycleConsumerUnitTable';
import { MdCloudDownload } from 'react-icons/md';
import { useCSVDownloader } from 'react-papaparse';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';

type ListState = {
  apportionmentCycleConsumerUnits: ApportionmentCycleConsumerUnit[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};
type apportionmentCycleConsumerUnitPortuguese = {
  id: number;
  Rateio: string;
  'Mês referente': Date;
  'Unidade consumidora': string;
  Cooperado: string;
  'Unidade Geradora': string;
};

const DASHBOARD_APPORTIONMENTS_CYCLE_ROUTE =
  '/dashboard/billing-cycle/apportionment-cycle';

const DASHBOARD_CREDIT_DISTRIBUTION_ROUTE =
  '/dashboard/billing-cycle/apportionment-cycle/credit-distribution';

const BREADCRUMB_PAGES: Page[] = [
  {
    name: 'Rateios',
    route: DASHBOARD_APPORTIONMENTS_CYCLE_ROUTE,
    current: false,
  },
  {
    name: 'Distribuição de créditos',
    route: DASHBOARD_CREDIT_DISTRIBUTION_ROUTE,
    current: true,
  },
];

const LIST_ERROR_TOAST: ToastProps = {
  title: 'Algo deu errado!',
  variant: 'danger',
  text: 'Não foi possível carregar a lista de Distribuição de Créditos',
};

export default function ApportionmentCycleConsumerUnitsPage() {
  const { addToast } = useToastContext();
  const { CSVDownloader, Type } = useCSVDownloader();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const [listState, setListState] = useState<ListState>({
    apportionmentCycleConsumerUnits: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [stateExport, setStateExport] = useState<ListState>({
    apportionmentCycleConsumerUnits: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const [
    apportionmentCycleConsumerUnitListExport,
    { data: dataExport, loading: loadingExport, error: errorExport },
  ] = useLazyQuery(APPORTIONMENT_CYCLE_CONSUMER_UNITS_QUERY, {
    variables: {
      pageSize: 5000,
      before: null,
      after: null,
    },
    notifyOnNetworkStatusChange: true,
  });

  const [apportionmentCycleConsumerUnitsList, { data, loading, error, refetch }] =
    useLazyQuery<ApportionmentCycleConsumerUnitsList>(
      APPORTIONMENT_CYCLE_CONSUMER_UNITS_QUERY,
      INITIAL_QUERY_STATE_CONFIG
    );

  useEffect(() => {
    apportionmentCycleConsumerUnitsList();
    apportionmentCycleConsumerUnitListExport();
  }, [
    apportionmentCycleConsumerUnitsList,
    apportionmentCycleConsumerUnitListExport,
  ]);

  const isError = error || errorExport;

  useEffect(() => {
    if (isError) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, isError]);

  const isLoading = loading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
      return;
    }
    if (data) {
      setListState({
        apportionmentCycleConsumerUnits:
          data.apportionmentCycleConsumerUnits.entries,
        hasNextPage: !!data.apportionmentCycleConsumerUnits.afterCursor,
        hasPreviousPage: !!data.apportionmentCycleConsumerUnits.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, data, isLoading, showLoading]);

  useEffect(() => {
    if (loadingExport) {
      showLoading();
      return;
    }

    if (dataExport) {
      setStateExport({
        apportionmentCycleConsumerUnits:
          dataExport.apportionmentCycleConsumerUnits.entries,
        hasNextPage: !!dataExport.apportionmentCycleConsumerUnits.afterCursor,
        hasPreviousPage: !!dataExport.apportionmentCycleConsumerUnits.beforeCursor,
      });
    }

    closeLoading();
  }, [closeLoading, dataExport, loadingExport, showLoading]);

  const handleClickNext = () => {
    refetch &&
      refetch({
        after: data?.apportionmentCycleConsumerUnits.afterCursor,
        before: null,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data?.apportionmentCycleConsumerUnits.beforeCursor,
      });
  };

  const apportionmentCycleConsumerUnitsPortuguese: apportionmentCycleConsumerUnitPortuguese[] =
    [];

  stateExport.apportionmentCycleConsumerUnits.map(
    (item: ApportionmentCycleConsumerUnit) =>
      apportionmentCycleConsumerUnitsPortuguese.push({
        id: item.id,
        Rateio: item.apportionmentCycleConsumerUnitPercentual
          ?.toString()
          .replace('.', ','),
        'Mês referente': item.apportionmentCycleConsumerUnitReferenceMonth,
        'Unidade consumidora':
          item.consumerUnit.consumerUnitPowerDistributionUnitIdentifier,
        Cooperado: item.consumerUnit.cooperativeMember.cooperativeMemberLegalName,
        'Unidade Geradora': item.generationUnit.generationUnitLegalName,
      })
  );

  useEffect(() => {
    apportionmentCycleConsumerUnitsList();
  }, [apportionmentCycleConsumerUnitsList]);

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm
          title="Distribuição de créditos"
          breadcrumbPages={BREADCRUMB_PAGES}
        >
          <></>
        </DashboardMainHeaderForm>
      }
    >
      <div className="flex items-center justify-end flex-1 mb-3">
        <CSVDownloader
          type={Type.Link}
          filename={'apportionments'}
          bom={true}
          config={{
            delimiter: ';',
          }}
          data={apportionmentCycleConsumerUnitsPortuguese}
        >
          <CsvButton variant={'secondaryGray'} size="sm" className="justify-end">
            <MdCloudDownload size={20} /> CSV
          </CsvButton>
        </CSVDownloader>
      </div>
      <div className="rounded-lg bg-gray-background">
        {listState && (
          <>
            <ApportionmentCycleConsumerUnitsTable
              apportionmentCycleConsumerUnits={
                listState.apportionmentCycleConsumerUnits
              }
            />
            <Pagination
              onNextClick={handleClickNext}
              onPreviousClick={handleClickBefore}
              disableNext={!listState.hasNextPage || isLoading}
              disableBefore={!listState.hasPreviousPage || isLoading}
            />
          </>
        )}
      </div>
      <LoadingOverlay />
    </Dashboard>
  );
}
